import Button from 'components/ui/Button';
import GeneralInfo from 'compositions/GeneralInfo';
import Head from 'next/head';
import { sanityFetch } from 'api';
import { General } from 'type/general';
import { GetStaticProps, NextPage } from 'next';
import { setGeneral } from 'store/generalSlice';
import { useAppDispatch } from 'store/hooks';
import { useEffect } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import generalData from 'translations/general.json';

type FourOhFourProps = {
  general: General;
  locale: string;
};

const FourOhFour: NextPage<FourOhFourProps> = ({ locale }) => {
  const dispatch = useAppDispatch();
  const translation = useTranslation();

  const lang = (locale || 'en') as any;

  const localeGeneralData = generalData as any;

  const general = {
    ...localeGeneralData[lang],
  } as General;

  useEffect(() => {
    dispatch(setGeneral(general));
  }, [general, dispatch]);

  return (
    <>
      <Head>
        <title>{general.fourOhFour.title}</title>
      </Head>

      <GeneralInfo
        title={general.fourOhFour.title}
        text={general.fourOhFour.preamble}
      >
        <Button url="/" color="blue">
          {translation('home.goHome')}
        </Button>
      </GeneralInfo>
    </>
  );
};

export const getStaticProps: GetStaticProps<FourOhFourProps> = async (
  context,
) => {
  const lang = context.locale || 'en';

  const query = `*[_type == "general" && __i18n_lang == "${lang}"]`;
  const [general] = await sanityFetch<General[]>(query);

  return {
    props: { general, locale: lang },
  };
};

export default FourOhFour;
